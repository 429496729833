<template>
  <div class="jobs" v-if="currentRoute">
    <stage stageLabel="Jobs" :big="true" :title="currentRoute.title.rendered" :image="currentRoute.acf.stageImage.url"/>
    <introduction
        fontWeight="normal"
        :subline="currentRoute.title.rendered"
        :maintext="currentRoute.acf.description"
        :contact="currentRoute.acf.Contact"
        :image="currentRoute.acf.Contact.image"
    />    
    <div class="jobs__content">
      <h3 class="jobs__sectionhead jobs__sectionhead--left">{{$i18n.locale === 'de' ? 'Aktuelle Ausschreibungen' : 'Current Job Openings'}}</h3>
      <div v-if="getJobs.length > 0" ref="joblist">
        <job-list :items="getJobs.slice((currentPage - 1) * 10, currentPage * 10)"/>
        <pagination v-if="getJobListProps.totalPages > 1" @change-current="setCurrentPage" :currentPage="currentPage" :totalPages="Number(getJobListProps.totalPages)"/>
      </div>
      <div v-else ref="joblist" class="jobs__content jobs__content--empty">
        <h2>There are currently no jobs open</h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/stage.vue'
import Introduction from '@/components/introduction.vue'
import JobList from '@/components/jobs-blocks.vue'
import {mapActions, mapGetters} from 'vuex'
import Pagination from '../components/pagination.vue'

export default {
  name: 'Jobs',
  components: {
    Stage,
    Introduction,
    JobList,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
      ...mapGetters([
          'getJobs',
          'getJobListProps',
          'getFetched'
      ]),
      currentRoute(){
          const getFetched =  this.getFetched[this.$route.name];
          return getFetched;
      },
  },
  created(){
      if(!this.currentRoute) {
          this.fetchCurrentPage(this.$route.name);
      }
      if(this.getJobs.length === 0) {
          this.fetchJobs(this.currentPage)
      }
  },
  watch: {
      currentPage( cur, old) {
          if(Number(this.getJobListProps.totalItems) > old * 10 && this.getJobs.length !== Number(this.getJobListProps.totalItems)) {
              this.fetchJobs(cur)
          }
          this.scrollTo(this.$refs.joblist);
      }
  },
  methods: {
    ...mapActions([
        'fetchJobs',
        'fetchCurrentPage',
    ]),
    setCurrentPage(val) {
        if(val <= this.getJobListProps.totalPages && val > 0) {
            this.currentPage = val
        }
    },
    scrollTo(ref) {
      window.scrollTo({
        top: ref.offsetTop - 100,
        left: 0,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.jobs {
  margin-top: 86px;
  padding: 0 .8rem;
  &__content {
    max-width: 1140px;
    margin: auto;
    padding:  0 .8rem;
    &--empty {
      text-align: center;
      margin-bottom: 86px;
    }
  }
  &__sectionhead {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family:"neue-plak";
    font-weight: 500;
    color: #182952;
    margin: 0 0 65px 0;
    &--left {
        justify-content: flex-start;
    }
  }
}
</style>
